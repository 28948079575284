<template>
  <b-modal
    id="modal-from-sucursal"
    v-model="modalSucursal"
    ref="modal"
    :title="tituloModal"
    no-close-on-backdrop
    :ok-disabled="submitted"
    :cancel-disabled="submitted"
    no-close-on-esc
    ok-title="Guardar"
    ok-variant="primary"
    cancel-variant="outline-secondary"
    cancel-title="Cerrar"
    size="lg"
    button-size="sm"
    @cancel.prevent="cerrarModal"
    @close.prevent="cerrarModal"
    @ok.prevent="validar"
  >
    <b-overlay
      :show="submitted"
      no-wrap
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="lg"
    />
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row class="mb-0-5">
          <b-col cols="12" md="6">
            <b-form-group
              label-cols="12"
              label-cols-lg="4"
              label="* NRO SUCURSAL"
              label-for="nro-sucursal"
            >
              <validation-provider
                #default="{ errors }"
                name="Nro Sucursal"
                rules="required|positive"
              >
                <b-form-input
                  id="nro-sucursal"
                  v-model.number="form.numero_sucursal"
                  @keyup.enter="validar()"
                  lazy-formatter
                  :formatter="textFormatter"
                  :state="errors.length > 0 ? false : null"
                  size="sm"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.numero_sucursal"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mb-0-5">
          <b-col cols="12">
            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label="* DIRECCION"
              label-for="direccion"
            >
              <validation-provider
                #default="{ errors }"
                name="Direccion"
                rules="required"
              >
                <b-form-input
                  id="direccion"
                  v-model="form.direccion"
                  @keyup.enter="validar()"
                  lazy-formatter
                  :formatter="textFormatter"
                  :state="errors.length > 0 ? false : null"
                  size="sm"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.direccion"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mb-0-5">
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              name="Categoria"
              rules="required"
            >
              <b-form-group
                label="* DEPARTAMENTO"
                label-for="departamento"
                label-cols="12"
                label-cols-lg="4"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  id="departamento"
                  v-model="departamento"
                  class="style-chooser select-size-sm"
                  label="nombre"
                  :options="departamentoItems"
                  @input="obtenerDepartamento"
                  :class="{ 'is-invalid': !!errors.length }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              name="Municipio"
              rules="required"
            >
              <b-form-group
                label="* MUNICIPIO"
                label-for="municipio"
                label-cols="12"
                label-cols-lg="4"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  id="municipio"
                  v-model="form.municipio_id"
                  class="style-chooser select-size-sm"
                  label="nombre"
                  :key="form.municipio_id"
                  :reduce="(op) => op.id"
                  :options="municipioItems"
                  :class="{ 'is-invalid': !!errors.length }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row class="mb-0-5">
          <b-col cols="12" md="6">
            <b-form-group
              label-cols="12"
              label-cols-lg="4"
              label="CELULAR"
              label-for="celular"
            >
              <validation-provider
                #default="{ errors }"
                name="Celular"
                rules=""
              >
                <b-form-input
                  id="celular"
                  v-model="form.celular"
                  @keyup.enter="validar()"
                  lazy-formatter
                  :formatter="textFormatter"
                  :state="errors.length > 0 ? false : null"
                  size="sm"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.celular"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group
              label-cols="12"
              label-cols-lg="4"
              label="TELEFONO"
              label-for="telefono"
            >
              <validation-provider
                #default="{ errors }"
                name="Telefono"
                rules=""
              >
                <b-form-input
                  id="telefono"
                  v-model="form.telefono"
                  @keyup.enter="validar()"
                  lazy-formatter
                  :formatter="textFormatter"
                  :state="errors.length > 0 ? false : null"
                  size="sm"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.telefono"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
import SucursalServices from "../services/index";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BModal,
  BButton,
  VBModal,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BForm,
  BOverlay,
  BFormSelect,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";

export default {
  components: {
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BOverlay,
    BFormSelect,
    vSelect,
  },
  props: ["item"],
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      form: {
        id: null,
        municipio_id: null,
        numero_sucursal: "",
        direccion: "",
        telefono: "",
        celular: "",
        estado: 1,
      },
      required,
      submitted: false,
      errores: [],
      modalSucursal: false,
      departamentoItems: [],
      municipioItems: [],
      empresaItems: [],
      departamento: null,
      estadoItems: [
        { text: "ACTIVO", value: 1 },
        { text: "INACTIVO", value: 0 },
      ],
    };
  },
  computed: {
    tituloModal() {
      return this.form.id
        ? "EDITAR SUCURSAL - SEDE PRINCIPAL"
        : "NUEVA SUCURSAL - SEDE PRINCIPAL";
    },
  },
  watch: {
    "form.numero_sucursal": function () {
      this.errores.numero_sucursal = [];
    },
    "form.direccion": function () {
      this.errores.direccion = [];
    },
    "form.celular": function () {
      this.errores.celular = [];
    },
    "form.telefono": function () {
      this.errores.telefono = [];
    },
    "form.municipio_id": function () {
      this.errores.municipio_id = [];
    },
  },
  methods: {
    validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (!this.submitted) {
            this.submitted = true;
            if (this.form.id) {
              this.actualizar();
            } else {
              this.registrar();
            }
          }
        }
      });
    },
    abrir(accion, data = []) {
      switch (accion) {
        case "registrar": {
          this.form.id = null;
          this.form.municipio_id = null;
          this.form.numero_sucursal = "";
          this.form.direccion = "";
          this.form.telefono = "";
          this.form.celular = "";
          this.form.estado = 1;
          this.modalSucursal = true;
          this.departamento = null;
          this.municipioItems = [];
          break;
        }
        case "actualizar": {
          this.form.id = data["id"];
          this.form.numero_sucursal = data["numero_sucursal"];
          this.form.direccion = data["sede_principal"]["direccion"];
          this.form.celular = data["sede_principal"]["celular"];
          this.form.telefono = data["sede_principal"]["telefono"];
          this.form.estado = data["estado"];
          let result = this.departamentoItems.filter(
            (departamento) =>
              departamento.id ==
              data["sede_principal"]["municipio"].departamento_id
          )[0];
          this.obtenerDepartamento(result);
          this.departamento = data["sede_principal"]["municipio"].departamento;
          this.form.municipio_id = data["sede_principal"]["municipio_id"];
          this.modalSucursal = true;

          break;
        }
      }
      this.errores = [];
      // setTimeout(() => {
      //   this.$nextTick(() => {
      //     document.getElementById("nit").focus();
      //   });
      // }, 100);
    },
    cerrarModal() {
      this.form.id = null;
      this.modalSucursal = false;
    },
    actualizarSucursal(item) {
      this.form.id = null;
      this.modalSucursal = false;
      this.$emit("actualizarSucursal", item);
      this.submitted = false;
    },

    // SERVICES
    async registrar() {
      try {
        const response = await SucursalServices.store(this.form);
        this.actualizarSucursal(response.data.sucursal);
        this.errores = [];
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    async actualizar() {
      try {
        const response = await SucursalServices.update(this.form.id, this.form);
        this.actualizarSucursal(response.data.sucursal);
        this.errores = [];
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    async getDepartamentos() {
      try {
        const response = await SucursalServices.getDepartamentos();
        this.departamentoItems = response.data;
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    obtenerDepartamento(value) {
      if (value) {
        this.municipioItems = value.municipios;
        this.form.municipio_id = null;
      } else {
        this.municipioItems = [];
        this.form.municipio_id = null;
      }
    },

    textFormatter(value) {
      return value.toUpperCase();
    },
    numberFormatter(value) {
      return (value * 1).toFixed(2);
    },
  },
  mounted() {
    this.getDepartamentos();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.vs__deselect {
  fill: rgb(255 255 255);
}
</style>